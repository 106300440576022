<template>
  <div class="card" id="mycard">

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 10px;" >
      <h4>
        <span class="font-weight-semibold">Jobwork Invoice</span> Information
      </h4>
    </div>



    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Jobworker</label>
            <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.ledger.code" >
              <option v-for="ledger in ledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Type</label>
            <select class="form-control select" required="" v-if="voucher"  v-model="voucher.type" @change="enableAddButton" >
              <option value="301">B2B</option>
              <option value="302">B2C</option>
              <option value="303">Composition</option>
              <option value="304">Exempted</option>
              <option value="305">Imported</option>
              <option value="306">NonGST</option>
            </select>
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Invoice No</label>
            <input type="text" class="form-control" placeholder="Invoice No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.ref_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Invoice Date</label>
            <input id="txtrefdate" type="date" class="form-control" v-if="voucher" v-model="voucher.ref_date" placeholder="Invoice Date" >
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs nav-tabs-top">
            <li class="nav-item"><a href="#top-tab1" class="nav-link active show" data-toggle="tab">Receipts</a></li>
            <li class="nav-item"><a href="#top-tab2" class="nav-link" data-toggle="tab">Credit Note</a></li>
            <li class="nav-item"><a href="#top-tab3" class="nav-link" data-toggle="tab">Debit Note</a></li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane fade active show" id="top-tab1">
              <table class="table table-no-bordered">
                <thead style="background-color: lightgrey">
                  <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Item / Service</th>
                  <th style="width:100px; text-align: right;">Rate</th>
                  <th style="width:75px; text-align: right;">Qty</th>
                  <th style="width:100px; text-align: right;">Taxable</th>
                  <th style="width:100px; text-align: right;">IGST</th>
                  <th style="width:100px; text-align: right;">CGST</th>
                  <th style="width:100px; text-align: right;">SGST</th>
                  <th style="width:150px; text-align: right;">Net Amount</th>
                  <th style="width: 30px;">Action</th>
                </tr>
                </thead>
                <tbody >
                  <tr v-for="(detail,index) in voucher.list" style="padding: 0px;">
                    <td style="text-align: center;"> {{index + 1}} </td>
                    <td style="padding: 0px;" >
                      <select class="form-control select-search" v-if="detail" v-model="detail.item.code" @change="rowValueEqualizer(index, 1)">
                        <option v-for="item in detailItems" v-bind:value="item.code">
                          {{ item.code }} - {{ item.name }}
                        </option>
                      </select>
                    </td>

                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
                    </td>

                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 4)"/>
                    </td>

                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.taxable_amt" @change="rowValueEqualizer(index, 5)"/>
                    </td>

                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.igst_amt" @change="rowValueEqualizer(index, 6)"/>
                    </td>
                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.cgst_amt" @change="rowValueEqualizer(index, 7)"/>
                    </td>
                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.sgst_amt" @change="rowValueEqualizer(index, 8)"/>
                    </td>

                    <td style="padding: 0px;" >
                      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.net_amt" readonly />
                    </td>

                    <td class="text-center" style="padding: 0px;" >
                      <button type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                <tr style="padding: 0px;">
                  <td style="padding: 0px;">
                    <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow"  :disabled="addRowButtonDisabled">
                      <i class="icon-plus3"></i>
                    </button>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-right" ><span style="padding-right: 15px;">{{ rowQtyTotal }}</span></td>
                  <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowTaxableTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowIgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowCgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowSgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span></td>

                  <td></td>


                </tr>
                </tfoot>
              </table>
            </div>

            <div class="tab-pane fade" id="top-tab2">

            </div>

            <div class="tab-pane fade" id="top-tab3">

            </div>

          </div>
        </div>


      </div>



      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <h3 style="font-size: 48px;color: blue">
            <!--voucher.gross_amount-->
            &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(voucher.round_off) ) }}</span>
          </h3>
        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">
            <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
            <div class="col-md-8">
              <input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off" >
            </div>
          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import numeral from 'numeral'
  import GstPanel from '@/components/v1/gst/GstPanel.vue'
  import { store } from '@/store/store.js'
  export default {
    name: 'InvoiceForm',
    components: {

    },
    store,
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    data () {
      return {
        addRowButtonDisabled:true,
        readonly: false,
        ledgers:[],
        detailItems:[],
        items : new Map(),
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowCgstTotal:0.0,
        rowSgstTotal:0.0,
        rowTaxableTotal:0.0,
        voucher: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
      }
    },
    created () {
      let self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;
      self.loadData();

      // self.$data.addRowButtonDisabled = true;
      if (self.$data.voucher.doc_date === '0001-01-01'){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }


    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      enableAddButton(){
        this.$data.voucher.list = [];
        this.$data.addRowButtonDisabled = (this.$data.voucher.type == 0);
      },
      addRow(){
        try {
          let self = this;
          self.$data.voucher.list.push(JSON.parse('{"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_cd":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"hsn":"","item":{"id":"","code":1,"nature":0,"ledger_group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"name":"","p_name":"","process":{"id":"","code":1,"name":"","nature":0,"p_name":"","sys_obj":false},"group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0},"subunit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"has_bom":false,"sysobj":false},"design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"unit":{"id":"","code":0,"name":"","digits":0},"rate":0,"min_qty":0,"max_qty":0,"wgt":0},"unit":{"id":"","code":1,"name":"","digits":0},"rate":0,"qty":0,"qty_txt":"","wgt":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'));
          var myobj = $('table>tbody>tr:last>td:nth-child(2)').children();
          $(myobj).focus();

        }catch (e) {
          alert(e);
        }
      },
      calculate_gst(detail){

        var taxrate = 0;
        if  (this.$data.voucher.type == 301) {
          taxrate = detail.gst_rate;
        }

        var tax_value = Math.round(detail.qty * detail.rate * (taxrate / 100) * 100);
        if (tax_value % 2 !== 0) {
          tax_value++;
        }
        tax_value = tax_value / 100;

        detail.tax_amt = tax_value;
        if (this.$data.voucher.ledger.state_cd == 0 || this.$data.voucher.ledger.state_cd == store.state.user.branch.state_cd) {
          detail.igst_amt = 0;
          detail.sgst_amt = tax_value / 2;
          detail.cgst_amt = tax_value / 2;
        } else {
          detail.igst_amt = tax_value;
          detail.sgst_amt = 0;
          detail.cgst_amt = 0;
        }

      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;
        let tax_value = 0;

        let detail = self.$data.voucher.list[rowIndex];
        switch (colIndex) {
          case 1:
            detail.item = self.$data.items.get(detail.item.code);
            detail.gst_rate = parseFloat(detail.item.group.gstrate);
            break;
          case 3://Product Rate
            // tax_value = Math.round(detail.qty * detail.rate * (detail.gst_rate/100) * 100);
            // if (tax_value%2 !== 0 ){
            //   tax_value++;
            // }
            // tax_value = tax_value/100;
            detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');

            self.calculate_gst(detail);
            // detail.tax_amt = numeral(tax_value).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 4://Product Qty

            // tax_value = Math.round(detail.qty * detail.rate * (detail.gst_rate/100) * 100);
            // if (tax_value%2 !== 0 ){
            //   tax_value++;
            // }
            // tax_value = tax_value/100;
            detail.taxable_amt = numeral(detail.qty * detail.rate).format('0.00');
            self.calculate_gst(detail);
            // detail.tax_amt = numeral(tax_value).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 5://Taxable value

            // detail.taxable_amt = parseFloat(detail.taxable_amt);
            // detail.rate = numeral(detail.taxable_amt / detail.qty).format('0.00');
            self.calculate_gst(detail);
            // detail.tax_amt = numeral(parseFloat(detail.taxable_amt) * (detail.gst_rate/100)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
          case 6:
          case 7:
          case 8:
            detail.tax_amt = numeral(parseFloat(detail.igst_amt) + parseFloat(detail.cgst_amt) + parseFloat(detail.sgst_amt)).format('0.00');
            detail.net_amt = numeral(parseFloat(detail.taxable_amt) + parseFloat(detail.tax_amt)).format('0.00');
            break;
        }
        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowSgstTotal += parseFloat(detail.sgst_amt);
          self.$data.rowCgstTotal += parseFloat(detail.cgst_amt);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },

      sumRowAmountTotal() {
        let self = this;

        self.$data.rowQtyTotal = 0.0;
        self.$data.rowAmountTotal = 0.0;
        self.$data.rowIgstTotal = 0.0;
        self.$data.rowCgstTotal = 0.0;
        self.$data.rowSgstTotal = 0.0;
        self.$data.rowTaxableTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.rowIgstTotal += parseFloat(detail.igst_amt);
          self.$data.rowSgstTotal += parseFloat(detail.sgst_amt);
          self.$data.rowCgstTotal += parseFloat(detail.cgst_amt);
          self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt);
        });
      },
      clear(){
        $('#btnSave').prop('disabled', false);
        let self = this;
        self.$data.voucher = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
        self.$data.addRowButtonDisabled = true;
        $('#cmbledger').focus();
      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/jobworker/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }

          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });


        //Fetch Detail Ledgers
        self.$data.detailItems = []
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if( _.isArray(resp.data)){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (itm) {
                Object.freeze(itm);
                self.$data.items.set(itm.code, itm);
              });
            }
            $(".select-search").select2();

          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        let self = this;

        try{
          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
          myvoucher.finyear = store.state.user.finyear;
          myvoucher.series = 302;
          myvoucher.type = parseInt(self.$data.voucher.type);
          myvoucher.is_cr = "Y";
          myvoucher.eway_date = moment().format('YYYY-MM-DD') + 'T00:00:00Z';
          myvoucher.doc_date = moment().format('YYYY-MM-DD') + 'T00:00:00Z';
          myvoucher.ref_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD') + 'T00:00:00Z';
          myvoucher.eway_bil_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD') + 'T00:00:00Z';
          myvoucher.round_off = parseFloat(self.$data.voucher.round_off);
          myvoucher.gross_amount = self.$data.rowAmountTotal;
          myvoucher.net_amount = myvoucher.gross_amount + myvoucher.round_off;
          myvoucher.process.code = 25;  //Purchase
          myvoucher.ibr = store.store.branch.code;

          myvoucher.list.forEach(function (detail){
            detail.branch.code = 1;//parseInt(detail.order_no);
            detail.order_no = 1;//parseInt(detail.order_no);
            detail.work_order = 1;//parseInt(detail.work_order);
            detail.qty_txt = detail.qty.toString();
            detail.qty = parseFloat(detail.qty);
            detail.unit.code = 1;
            detail.rate = parseFloat(detail.rate);
            detail.taxable_amt = parseFloat(detail.taxable_amt);
            detail.tax_amt = parseFloat(detail.tax_amt);
            detail.net_amt = parseFloat(detail.net_amt);
          });


          const requestOptions = {
            method:  (self.$data.voucher.code == 0 ? 'POST' : 'PUT' ),
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };

          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted){
              swal({
                title: 'Great',
                type: 'success',
                text: resp.message
              });
              $('#mycard').unblock();
              // self.clear();
            }else{
              $('#mycard').unblock();
              swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
            }
          }).catch(function (err) {
           $('#mycard').unblock();
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          });

        }catch (e) {

        }finally {

        }



      }
    }
  }
</script>

<style scoped>

</style>
