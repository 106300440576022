<template>

  <div class="panel" style="padding: 10px;">
    <div class="col-md-12">

      <!-- layout-->


          <h5 class="card-title">GST Detail</h5>


        <div class="form-group row">
          <label class="col-md-3 col-form-label">IGST Value:</label>
          <div class="col-md-9">
            <input type="text" class="form-control" :value="gst_rate" readonly>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label">CGST Value:</label>
          <div class="col-md-9">
            <input type="number" class="form-control" value="0.00" readonly>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label">SGST Value:</label>
          <div class="col-md-9">
            <input type="number" class="form-control" value="0.00" readonly>
          </div>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-primary legitRipple" @click="demoClick">Update<i class="icon-paperplane ml-2"></i></button>
        </div>



      <!-- /layout -->

    </div>
  </div>

</template>

<script>
  export default {
    name: 'GstPanel',
    props: {
      gst_taxable_amount: { default: ()=> 0.0, required: true},
      gst_tax_rate: { default: ()=> 0.0, required: true},
    },
    data(){
      return {
        taxableamount: 0.0,
        taxrate:0,
        igst: 0.0,
        cgst: 0.0,
        sgst: 0.0,
        totaltax: 0.0,
        netamount: 0.0
      }
    },
    created () {

    },
    mounted () {

    },
    computed:{
      gst_rate(){
        return parseFloat(this.gst_tax_rate);
      }
    },
    watcher: {
      taxrate () {
        // this.taxrate = parseFloat(newVal);
        alert(this.taxrate);
      }
    },
    methods:{
      demoClick(){
        this.taxrate = this.gst_tax_rate;
        // alert(this.gst_tax_rate);
      }
    }
  }
</script>

<style scoped>

</style>
